<template>
  <div class="agencyEntrustmentDetailsTwo">
    <div class="agencyEntrustmentDetailsTwo-black">
      <div class="black-text" @click="handleBlack">
        <i class="el-icon-arrow-left"></i>
        待办委托列表
      </div>
    </div>

    <div class="agencyEntrustmentDetailsTwo-title">{{ details.taskName }}</div>
    <div class="agencyEntrustmentDetailsTwo-subtitle">
      <div class="btn" @click="lookUpPeople">
        {{ identityType == 1 ? "查看承办人" : "查看用户" }}
      </div>
      <div class="timeBox">
        <div class="timeBox-text">
          预计 {{ details.plannedCompletionTime }}完成
        </div>
        <i
          class="timeBox-icon"
          @click="modifyTime = true"
          v-if="identityType == 1"
        ></i>
      </div>
      <el-progress
        :percentage="details.progress"
        :stroke-width="8"
        :format="format"
      ></el-progress>
    </div>
    <!--  步骤条 -->
    <ul class="triangle_step">
      <li
        @click="StepClick(item.parentId, index)"
        :class="{ cur: active - 1 >= index, clikc: i == index }"
        v-for="(item, index) in obj"
        style="width: 100%"
        :key="index"
      >
        <i class="el-icon-circle-check" v-if="active - 1 >= index"></i>
        <i v-else class="el-icon-time"></i>
        <span>{{ item.stepName }}</span>
        <div
          v-if="index < obj.length - 1"
          :class="[
            active - 1 >= index ? 'jiaoActive' : 'jiao',
            i == index ? 'jiaoclikc' : '',
          ]"
        ></div>
        <div class="interval"></div>
      </li>
    </ul>

    <div class="agencyEntrustmentDetailsTwo-table">
      <el-table
        :data="tableData"
        :row-key="getRowKeys"
        :expand-row-keys="expands"
        style="width: 100%"
      >
        <el-table-column width="20px" type="expand">
          <template slot-scope="props">
            <el-form
              label-position="left"
              inline
              class="demo-table-expand"
              v-if="props.row.templateList != null"
            >
              <el-form-item style="width: 100%">
                <el-table
                  :data="props.row.templateList"
                  border
                  style="width: 100%"
                >
                  <el-table-column width="250" label="文件名">
                    <template slot-scope="scope">
                      <div class="text">{{ scope.row.fileName }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="备注">
                    <template slot-scope="scope">
                      <div class="text">{{ scope.row.remark }}</div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    align="center"
                    width="100"
                    header-align="center"
                    label="操作"
                  >
                    <template slot-scope="scope">
                      <div
                        v-if="scope.row.fileUrl"
                        @click="download(scope.row.fileUrl)"
                        class="primarybtn"
                      >
                        下载
                      </div>
                      <div v-else>无</div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-form>
            <el-form
              label-position="left"
              inline
              class="demo-table-expand center"
              v-else
            >
              <el-form-item label="暂无样板">
                <div class="kong"></div>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>

        <el-table-column
          label="流程"
          align="left"
          header-align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="text">
              <span class="numColor">{{ scope.$index + 1 }}.</span>
              <span class="textFont">{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="耗时" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="text">{{ scope.row.needtime }}小时</div>
          </template>
        </el-table-column>

        <el-table-column
          label="需客户知悉、配合事项"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="text">{{ scope.row.coordinate }}</div>
          </template>
        </el-table-column>

        <el-table-column
          label="执业规范要求"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="text">{{ scope.row.require }}</div>
          </template>
        </el-table-column>

        <el-table-column label="反馈" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="btnGroup">
              <!-- <div class="btn" @click="check(scope.row, scope.$index)">
                查看样板
              </div> -->
              <div class="btn" @click="feedback(scope.row)">查看反馈</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="进度"
          align="center"
          header-align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="text">
              <span v-if="scope.row.status == 0" class="textFont bgcprimary">{{
                scope.row.statusText
              }}</span>
              <span v-if="scope.row.status == 1" class="textFont bgcplain">{{
                scope.row.statusText
              }}</span>
              <span v-if="scope.row.status == 2" class="textFont bgcplain">{{
                scope.row.statusText
              }}</span>
              <span v-if="scope.row.status == 3" class="textFont bgcwarning">{{
                scope.row.statusText
              }}</span>
              <span v-if="scope.row.status == 4" class="textFont bgcdanger">{{
                scope.row.statusText
              }}</span>
              <span v-if="scope.row.status == 5" class="textFont bgcsuccess">{{
                scope.row.statusText
              }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- <div class="agencyEntrustmentDetailsTwo-btn">点击提交</div> -->

    <!-- 反馈意见弹窗 -->
    <el-dialog
      :visible.sync="opinionDialog"
      top="0"
      :before-close="opinionHandleClose"
      class="opinion"
    >
      <span slot="title" class="dialog-title">
        <div class="title-text">反馈意见</div>
      </span>
      <el-input
        type="textarea"
        :placeholder="identityType == 2 ? '请输入承办反馈' : '请输入督办反馈'"
        resize="none"
        v-model="textarea"
      >
      </el-input>
      <el-upload
        class="upload-demo"
        action=""
        drag
        ref="upload"
        :on-change="uploadChange"
        :auto-upload="false"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
      </el-upload>

      <div class="radioGroup" v-if="identityType == 2">
        <div class="radioGroup-label">反馈类型:</div>
        <el-radio-group @change="handleRadio" v-model="radioOne">
          <el-radio :label="1">承办反馈</el-radio>
          <el-radio :label="3">提请验收</el-radio>
        </el-radio-group>
      </div>

      <div class="radioGroup" v-else>
        <div class="radioGroup-label">反馈类型:</div>
        <el-radio-group @change="handleRadio" v-model="radioTwo">
          <el-radio :label="2">督办反馈</el-radio>
          <el-radio :label="4">验收不通过</el-radio>
          <el-radio :label="5">验收通过</el-radio>
        </el-radio-group>
      </div>

      <span slot="footer" class="dialog-footer">
        <div class="footer-btn sure" @click="submit">提交</div>
        <div class="footer-btn" @click="opinionHandleClose">取消</div>
      </span>
    </el-dialog>

    <!-- 审核付款弹窗 -->
    <el-dialog :visible.sync="paymentDialog" top="0" class="payment">
      <span slot="title" class="dialog-title" @click="openOpinionDialog">
        <i class="inspected-icon"></i>
        <div class="title-text">反馈意见</div>
      </span>

      <div class="content">
        <i class="content-icon"></i>
        <div class="content-text">同意付款吗？</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <div class="footer-btn">同意</div>
        <div class="footer-line"></div>
        <div class="footer-btn" @click="paymentDialog = false">不同意</div>
      </span>
    </el-dialog>

    <!-- 反馈弹窗 -->
    <el-dialog
      title="反馈信息"
      :visible.sync="feedbackDialog"
      class="feedback"
      width="45%"
      :before-close="feedbackClose"
    >
      <div class="content">
        <div class="btnGorup">
          <el-button type="primary" @click="handleClick">反馈</el-button>
        </div>
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in feedbackList"
            :key="index"
            :timestamp="item.createTime"
            placement="top"
          >
            <el-card>
              <h4 class="info">{{ item.feedback }}</h4>
              <div
                class="jumpA"
                v-if="item.fileName != null && item.fileUrl != null"
              >
                附件：
                <a :href="`${http}${item.fileUrl}`">{{ item.fileName }}</a>
              </div>
              <div class="lastBox">
                <p>
                  <span @click="openUserInfo(item)"
                    ><img
                      :src="
                        item.userInfoVO.headImg == null
                          ? require('assets/images/user.png')
                          : item.userInfoVO.headImg
                      "
                      @error="imgError(item)"
                      alt=""
                    />{{
                      item.userInfoVO.realName == null
                        ? item.userInfoVO.username
                        : item.userInfoVO.realName
                    }}</span
                  >
                  : 提交于 {{ item.createTime }}
                </p>
                <div class="btnGroup" v-if="item.isRead == true">
                  <el-tag>已读</el-tag>
                </div>
                <div class="btnGroup" v-else>
                  <el-tag type="info">未读</el-tag>
                  <el-button type="danger" size="small" @click="recall(item)"
                    >撤回</el-button
                  >
                </div>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>

    <!-- 查看样板弹窗 -->
    <!-- <el-dialog
      title="样板信息"
      :visible.sync="modelInfoDialog"
      class="modelInfo"
      width="45%"
      :before-close="modelInfoClose"
    >
      <el-table :data="fileList" border style="width: 100%">
        <el-table-column label="文件名">
          <template slot-scope="scope">
            <div class="text">{{ scope.row.fileName }}</div>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          width="100"
          header-align="left"
          label="操作"
        >
          <template slot-scope="scope">
            <div @click="download(scope.row.fileUrl)" class="primarybtn">
              下载
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog> -->

    <!-- 修改时间弹窗 -->
    <el-dialog
      title="修改时间"
      :visible.sync="modifyTime"
      width="30%"
      class="modifyTime"
      :before-close="modifyTimeClose"
    >
      <div class="content">
        <div class="content-label">预计完成时间：</div>
        <el-date-picker v-model="timeValue" type="date" placeholder="选择日期">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modifyTimeClose()">取 消</el-button>
        <el-button type="primary" @click="modifyTimeSure">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 用户信息弹窗 -->
    <el-dialog
      title="用户信息"
      :visible.sync="userInfoDia"
      class="userInfoDia"
      width="30%"
    >
      <div class="userInfo" ref="user">
        <div class="user">
          <div class="headImg">
            <el-image
              style="
                width: 43px;
                height: 43px;
                margin-right: 10px;
                border-radius: 50%;
                cursor: pointer;
              "
              :src="
                userInfoVO.headImg == null
                  ? require('assets/images/user.png')
                  : userInfoVO.headImg
              "
              fit="cover"
            >
            </el-image>

            <div class="grade" v-if="userInfoVO.memberlevel == 0"></div>
            <div class="grade" v-else-if="userInfoVO.memberlevel == 1"></div>
            <div
              class="grade vipIcon"
              v-else-if="userInfoVO.memberlevel == 2"
            ></div>
            <div class="grade svipIcon" v-else></div>
          </div>
          <div class="user-text">
            <div class="user-name">
              {{
                userInfoVO.realName == null
                  ? userInfoVO.username
                  : userInfoVO.realName
              }}
            </div>
            <div class="user-id">ID:{{ userInfoVO.number }}</div>
            <div class="user-id">手机号:{{ userInfoVO.username }}</div>
            <div class="user-id">微信:{{ userInfoVO.wechatNumber }}</div>
            <div class="user-id">邮箱:{{ userInfoVO.mail }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getcheckDetail,
  getfeedbackList,
  uploadList,
  recallFeedback,
  modifyTime,
  getUserInfo,
} from "api/copyrightService";
export default {
  data() {
    return {
      tableData: [],
      opinionDialog: false, //意见弹窗
      textarea: "", //意见font
      paymentDialog: false, //提交付款弹窗
      feedbackDialog: false, //反馈弹窗
      modelInfoDialog: false, //查看样板弹窗
      modifyTime: false, //修改时间弹窗
      taskId: "",
      details: "",
      feedbackList: [],
      http: "https://fafs.antiplagiarize.com/",
      fileList: [], //样板文件list
      identityType: "", //发起人  1    承办人  2
      radioOne: 1, //承办人radio
      radioTwo: 2, //用户radio
      uploadFileList: [], //文件上传list
      taskdetailId: null, //任务详情ID
      projectdetailId: null,
      timeValue: "",
      expands: [],
      userInfoVO: "",
      userInfoDia: false,
      userId: null,
      // 阶段数组
      obj: [],
      // 步骤条
      active: 0,
      // 分类阶段数组
      arr: [],
      i: 0,
    };
  },
  methods: {
    // 反馈类型
    handleRadio(val) {
      if (val == 1 || val == 2) {
        this.textarea = "";
      } else if (val == 3) {
        this.textarea = "请验收";
      } else if (val == 4) {
        this.textarea = "验收不通过";
      } else if (val == 5) {
        this.textarea = "验收通过";
      }
    },
    // 被点击项
    StepClick(id, index) {
      this.i = index;
      this.tableData = this.arr.filter((item, index) => {
        if (item.parentId == id) {
          return item;
        }
      });
    },
    // 返回
    handleBlack() {
      this.$router.go(-1);
    },
    // 打开用户信息弹窗
    openUserInfo(item) {
      this.userInfoVO = item.userInfoVO;
      this.userInfoDia = true;
    },
    getRowKeys(row) {
      return row.taskdetailId;
    },
    // 图片容错触发
    imgError(item) {
      item.userInfoVO.headImg = require("assets/images/user.png");
    },
    // 查看人员
    async lookUpPeople() {
      if (this.identityType == 1) {
        //用户
        let data = {
          userId: this.details.handleId,
        };
        const res = await getUserInfo(data);
        if (res.code === 200) {
          this.userInfoDia = true;
          this.userInfoVO = res.data;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      } else {
        //承办人
        let data = {
          userId: this.details.userId,
        };
        const res = await getUserInfo(data);
        if (res.code === 200) {
          this.userInfoDia = true;
          this.userInfoVO = res.data;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      }
    },
    // 撤回按钮
    async recall(item) {
      let formData = new FormData();
      formData.append("feedbackId", item.id);
      const res = await recallFeedback(formData);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "撤销成功！",
        });
        this.feedBackList();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 修改时间确定
    async modifyTimeSure() {
      // 转换时间格式
      let curtime = new Date(this.timeValue);
      let year = curtime.getFullYear();
      let month = curtime.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let date = curtime.getDate();
      if (date < 10) {
        date = "0" + date;
      }
      let time = `${year}-${month}-${date} 00:00:00`;
      const data = {
        taskId: this.taskId,
        plannedCompletionTime: time,
      };
      const res = await modifyTime(data);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "修改成功！",
        });
        this.modifyTimeClose();
        this.getDetails();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 修改时间弹窗关闭触发
    modifyTimeClose() {
      this.modifyTime = false;
      this.timeValue = "";
    },
    // 查看按钮
    async check(row, index) {
      this.fileList = row.templateList;
      this.modelInfoDialog = true;
    },
    // 关闭查看弹窗触发
    modelInfoClose() {
      this.modelInfoDialog = false;
    },
    //下载样板
    download(url) {
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = `${this.http}${url}`;
      a.click();
      a.remove();
    },
    // 反馈信息弹窗
    feedback(row) {
      this.feedbackDialog = true;
      this.taskdetailId = row.taskdetailId;
      this.projectdetailId = row.projectdetailId;
      this.feedBackList();
    },
    // 反馈信息输入弹框
    handleClick() {
      this.feedbackDialog = false;
      this.opinionDialog = true;
    },
    // 反馈信息输入弹框确定
    submit() {
      if (this.textarea == "") {
        this.$message({
          type: "error",
          message: "请输入反馈意见!",
        });
        return;
      }

      this.$confirm("确认提交当前反馈信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.feedbackSumbit();
          this.getDetails();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 反馈提交
    async feedbackSumbit() {
      // 1用户
      if (this.identityType == 1) {
        let formData = new FormData();
        this.uploadFileList.map((item) => {
          console.log(item);
          formData.append("files", item.raw);
        });
        formData.append("taskDetailId", this.taskdetailId);
        formData.append("type", this.identityType);
        formData.append("feedback", this.textarea);
        formData.append("status", this.radioTwo);
        const res = await uploadList(formData);
        if (res.code === 200) {
          this.opinionHandleClose();
          this.$router.go(0);
          this.$message({
            type: "success",
            message: "成功！",
          });
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      } else {
        //2承办人
        let formData = new FormData();
        this.uploadFileList.map((item) => {
          formData.append("files", item.raw);
        });
        formData.append("taskDetailId", this.taskdetailId);
        formData.append("type", this.identityType);
        formData.append("feedback", this.textarea);
        formData.append("status", this.radioOne);
        const res = await uploadList(formData);
        if (res.code === 200) {
          this.opinionHandleClose();
          this.$router.go(0);
          this.$message({
            type: "success",
            message: "成功！",
          });
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      }
    },
    // 获取反馈列表
    async feedBackList() {
      this.feedbackList = [];
      let formData = new FormData();
      formData.append("projectDetailId", this.projectdetailId);
      formData.append("taskDetailId", this.taskdetailId);
      const res = await getfeedbackList(formData);
      if (res.code === 200) {
        this.feedbackList = res.data;
      } else if (res.code === 2001) {
        this.feedbackList = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //反馈弹窗关闭
    feedbackClose() {
      this.feedbackDialog = false;
    },
    // 进度条返回完成度
    format(percentage) {
      return `完成度${percentage}%`;
    },
    // 打开意见弹窗
    openOpinionDialog() {
      this.inspectedDialog = false;
      this.opinionDialog = true;
    },
    // 关闭反馈意见弹窗触发
    opinionHandleClose() {
      this.opinionDialog = false;
      this.textarea = "";
      this.uploadFileList = [];
      this.radioOne = 1;
      this.radioTwo = 2;
      this.$refs.upload.clearFiles();
    },
    // 打开提交付款弹窗
    openPaymentDialog() {
      this.paymentDialog = true;
    },
    // 文件上传
    uploadChange(file, fileList) {
      this.uploadFileList = fileList;
    },
    async getDetails() {
      let formData = new FormData(); // 当前为空
      formData.append("taskId", this.taskId);
      let res = await getcheckDetail(formData);
      if (res.code === 200) {
        this.arr = res.data.taskDetailCheckVo;
        this.obj = res.data.step;
        this.obj.forEach((item, index) => {
          if (item.status == this.obj[0].parentId) {
            this.active = item.sort;
          }
        });
        this.tableData = this.arr.filter((item, index) => {
          if (item.parentId == this.obj[0].parentId) {
            return item;
          }
        });

        this.details = res.data;

        //转换时间格式
        let myDate = new Date(this.details.plannedCompletionTime);
        let year = myDate.getFullYear();
        let month = myDate.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let date = myDate.getDate();
        if (date < 10) {
          date = "0" + date;
        }
        this.details.plannedCompletionTime =
          year + "年" + month + "月" + date + "日";
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
  },
  created() {
    this.taskId = this.$route.query.taskId;
    this.identityType = this.$route.query.identityType;
    this.getDetails();
  },
};
</script>

<style lang="scss" scoped>
.agencyEntrustmentDetailsTwo {
  &-black {
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: 21px;
    border-bottom: 1px solid #f9f9f9;
    .black-text {
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      .el-icon-arrow-left {
        color: #333333;
      }
    }
  }
  &-title {
    text-align: center;
    margin-top: 19px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }
  &-subtitle {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: relative;
    .timeBox {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      margin-right: 32px;
      display: flex;
      i {
        width: 13px;
        height: 12px;
        margin-left: 5px;
        @include backgroundGroup("../../../../assets/images/modify.png");
        cursor: pointer;
      }
    }
    .btn {
      position: absolute;
      left: 20px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4587ff;
      cursor: pointer;
    }
    ::v-deep .el-progress {
      width: 230px;
      display: flex;
      align-items: center;
      .el-progress__text {
        margin-left: 15px;
        font-size: 14px !important;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        width: 105px;
      }
    }
  }
  &-table {
    display: flex;
    justify-content: center;
    margin: 30px 30px 0;
    ::v-deep.el-table {
      thead {
        .cell {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }
      }
      tbody {
        .text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          .numColor {
            color: #eb441e;
          }
        }
        .btnGroup {
          display: flex;
          justify-content: center;

          .btn {
            color: #4587ff;
            cursor: pointer;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            margin-left: 10px;
            &:first-of-type {
              margin-left: 0;
            }
          }

          .el-button {
            margin-left: 20px;
            height: 32px;
            padding: 0 10px;
          }
        }
        .center {
          text-align: center !important;
        }
      }
    }
  }
  &-btn {
    width: 97px;
    height: 41px;
    background: #4c8cff;
    border: 1px solid #4587ff;
    border-radius: 3px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 41px;
    cursor: pointer;
    margin: 30px 0 0 114px;
  }
  // 反馈意见
  .opinion {
    ::v-deep .el-dialog {
      width: 583px;
      // height: 522px;
      background: #ffffff;
      box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
      border-radius: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .el-dialog__header {
        .dialog-title {
          display: flex;
          .title-text {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #393939;
          }
        }
      }
      .el-dialog__body {
        padding: 33px 30px 0;
        .el-textarea {
          height: 121px;
          .el-textarea__inner {
            height: 95px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
          }
        }
        .el-upload-dragger {
          width: 525px;
          height: 115px;
          .el-icon-upload {
            margin: 15px 0 16px;
          }
        }
        .radioGroup {
          margin-top: 30px;
          display: flex;
          .el-radio-group {
            margin-left: 15px;
          }
        }
      }
      .el-dialog__footer {
        padding: 20px 31px 30px;
        display: flex;
        justify-content: flex-end;
        .dialog-footer {
          display: flex;
          .footer-btn {
            width: 71px;
            height: 41px;
            border: 1px solid #cecece;
            border-radius: 3px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            text-align: center;
            line-height: 41px;
            cursor: pointer;
          }
          .sure {
            color: #fff;
            background: #4587ff;
            margin-right: 12px;
          }
        }
      }
    }
  }
  // 支付弹窗
  .payment {
    ::v-deep .el-dialog {
      width: 342px;
      height: 200px;
      background: #ffffff;
      box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
      border-radius: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .el-dialog__header {
        .dialog-title {
          display: flex;
          align-items: center;
          cursor: pointer;
          .inspected-icon {
            width: 13px;
            height: 13px;
            @include backgroundGroup(
              "../../../../assets/images/inspected-icon.png"
            );
            margin-right: 6px;
          }
          .title-text {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
          }
        }
      }
      .el-dialog__body {
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;
        margin-top: 41px;
        .content {
          display: flex;
          align-items: center;
          .content-icon {
            width: 18px;
            height: 18px;
            @include backgroundGroup("../../../../assets/images/renzheng.png");
            margin-right: 10px;
          }
          .content-text {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
      }
      .el-dialog__footer {
        padding: 0 70px;
        margin-top: 50px;
        .dialog-footer {
          display: flex;
          justify-content: space-between;
          .footer-btn {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            cursor: pointer;
            color: #4587ff;
          }
          .footer-line {
            width: 1px;
            height: 20px;
            background: #e8e8e8;
          }
        }
      }
    }
  }
  // 反馈
  .feedback {
    ::v-deep .el-dialog {
      .el-dialog__body {
        .content {
          max-height: 625px;
          overflow-y: scroll;
          .btnGorup {
            margin-bottom: 20px;
            width: 95%;
            margin-left: 25px;
          }
          .el-timeline-item__content {
            width: 95%;
            .el-card__body {
              padding: 20px;
              line-height: 25px;
              position: relative;
              .lastBox {
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                  display: flex;
                  align-items: center;
                  span {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    color: #4587ff;
                    img {
                      margin-right: 10px;
                      width: 25px;
                      height: 25px;
                      border-radius: 50%;
                    }
                  }
                }
                .btnGroup {
                  .el-tag {
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // 查看样板
  .modelInfo {
    ::v-deep .el-dialog {
      height: 500px;
    }
  }
  // 修改时间
  .modifyTime {
    ::v-deep .el-dialog {
      .el-dialog__body {
        .content {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  // 用户信息弹窗
  .userInfoDia {
    ::v-deep .el-dialog {
      .userInfo {
        margin: 0px;
        width: 320px;
        height: 200px;
        box-sizing: border-box;
        // display: flex;
        // flex-wrap: wrap;
        padding: 15px;
        line-height: 20px;
        &-item {
          display: flex;
          width: 200px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .user {
          padding: 10px 0 9px 0;
          display: flex;
          //border-bottom: 1px solid #f4f4f4;

          .headImg {
            position: relative;
            .grade {
              position: absolute;
              width: 14px;
              height: 14px;
              right: 5px;
              bottom: 95px;
            }

            .vipIcon {
              @include backgroundGroup("~assets/images/user-vip.png");
            }

            .svipIcon {
              @include backgroundGroup("~assets/images/user-svip.png");
            }
          }

          .user-text {
            margin-top: 4px;
            margin-left: 10px;
            .user-name {
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
            }

            .user-id {
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
              margin-top: 9px;
            }
          }
        }
      }
    }
  }

  // 步骤条
  .triangle_step {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    li {
      cursor: pointer;
      height: 40px;
      background: #d7d8da;
      color: #000;
      font-weight: 800;
      text-align: center;
      line-height: 40px;
      /*width: 20%;*/
      /*flex-basis: 100%;*/
      position: relative;

      span {
        margin-left: 10px;
      }
    }
    li.cur {
      background: #67c23a;
      color: #fff;
    }
    li.clikc {
      background: #409eff;
      color: #fff;
    }

    /*三角形绘制*/
    .jiao {
      width: 0;
      height: 0;
      border-top: 20px solid transparent; /*高度一半*/
      border-left: 20px solid #d7d8da; /*调整宽度*/
      border-bottom: 20px solid transparent; /*高度一半*/
      position: absolute;
      right: -20px; /*跟宽度保持一致*/
      top: 0;
      z-index: 2;
    }
    .jiaoActive {
      width: 0;
      height: 0;
      border-top: 20px solid transparent; /*高度一半*/
      border-left: 20px solid #67c23a; /*调整宽度*/
      border-bottom: 20px solid transparent; /*高度一半*/
      position: absolute;
      right: -20px; /*跟宽度保持一致*/
      top: 0;
      z-index: 2;
    }
    /*大3个px的边 26-20/2*/
    .interval {
      width: 0;
      height: 0;
      border-top: 26px solid transparent; /*高度一半*/
      border-left: 26px solid #fff; /*调整宽度*/
      border-bottom: 26px solid transparent; /*高度一半*/
      position: absolute;
      right: -26px; /*跟宽度保持一致*/
      top: -6px;
      z-index: 1;
    }

    .jiaoclikc {
      border-top: 20px solid transparent; /*高度一半*/
      border-left: 20px solid #409eff; /*调整宽度*/
      border-bottom: 20px solid transparent; /*高度一半*/
    }
  }
}

.bgcplain {
  padding: 5px;
  background-color: #f2e6e6;
  color: #ad5a5a;
  border: 1px solid #ad5a5a;
  border-radius: 5px;
}
.bgcwarning {
  padding: 5px;
  background-color: #fdf6ec;
  color: #e6a23c;
  border: 1px solid #e6a23c;
  border-radius: 5px;
}
.bgcdanger {
  padding: 5px;
  background-color: #fef0f0;
  color: #f78989;
  border: 1px solid #f78989;
  border-radius: 5px;
}
.bgcprimary {
  padding: 5px;
  background-color: #ecf5ff;
  color: #409eff;
  border: 1px solid #409eff;
  border-radius: 5px;
}
.bgcsuccess {
  padding: 5px;
  background-color: #f0f9eb;
  color: #6ec543;
  border: 1px solid #6ec543;
  border-radius: 5px;
}
::v-deep .el-form-item__content {
  width: 100%;
}

.primarybtn {
  color: #409eff;
  cursor: pointer;
}

.demo-table-expand {
  ::v-deep .el-form-item {
    .el-form-item__content {
      .el-table {
        .el-table__cell {
          padding: 5px 0;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-tooltip__popper {
  max-width: 400px;
  margin-left: 80px;
}
.el-tooltip__popper.is-dark {
  background: #fff !important;
  color: #606266 !important;
  font-size: 14px;
  box-shadow: 0 3px 3px 3px rgba(151, 151, 158, 0.5);
}
</style>
